const SEARCH_API_BASE_PATH = '/api/search';
const ALERTS_SERVICE_BASE_PATH = `${SEARCH_API_BASE_PATH}/alert`;
const AVAILABLE_FILTER_VALUES = `${SEARCH_API_BASE_PATH}/availableFilterValues`;
const BESTSELLER_PATH = '/bestseller';
const BOOKMARKS_SERVICE_BASE_PATH = `${SEARCH_API_BASE_PATH}/bookmarks`;
const CART_SERVICE_BASE_PATH = `${SEARCH_API_BASE_PATH}/cart`;
const COMPATS_PATH = `${SEARCH_API_BASE_PATH}/compats`;
const LOCATION_PATH = `${SEARCH_API_BASE_PATH}/location`;
const PICTURES_CAROUSEL_SERVICE_BASE_PATH = `${SEARCH_API_BASE_PATH}/picturesCarousel`;
const SEARCH_MAPS_BASE_PATH = '/api';
const SEARCH_URL_BASE_PATH = `${SEARCH_API_BASE_PATH}/searchUrl`;
const SUGGESTIONS_PATH = `${SEARCH_API_BASE_PATH}/suggestions`;

module.exports = {
  ALERTS_SERVICE_BASE_PATH,
  AVAILABLE_FILTER_VALUES,
  BESTSELLER_PATH,
  BOOKMARKS_SERVICE_BASE_PATH,
  CART_SERVICE_BASE_PATH,
  COMPATS_PATH,
  LOCATION_PATH,
  PICTURES_CAROUSEL_SERVICE_BASE_PATH,
  SEARCH_API_BASE_PATH,
  SEARCH_MAPS_BASE_PATH,
  SEARCH_URL_BASE_PATH,
  SUGGESTIONS_PATH,
};
