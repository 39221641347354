import { isCancel, CancelToken } from 'nordic/restclient/cancel';
import { createSetter, getSnapshot, useSubscribedState } from 'nordic/page/store';

import ApiService from '../../../../services/maps';
import getHistory from '../../../lib/history';
import { updateURL } from '../../../lib/analytics';
import { LAYOUTS } from '../../../constants';
import { getRepeatProductObject } from '../search';

export const useLocationSearch = () => useSubscribedState().locationSearch;
export const useLocationSearchInitialState = () => useLocationSearch().initialState;

export const getLocationSearchCancelToken = () => getSnapshot().locationSearch.cancelToken;
export const getLocationSearchLastLocation = () => getSnapshot().locationSearch.lastLocation;
export const setLocationSearchError = createSetter(({ locationSearch }, payload) => {
  locationSearch.error = payload;
});
export const setLocationSearchCancelToken = createSetter(({ locationSearch }, payload) => {
  locationSearch.cancelToken = payload;
});
export const setLocationSearchIsLoading = createSetter(({ locationSearch, search }, payload) => {
  locationSearch.isLoading = payload;
  locationSearch.isLoadingResults = payload;
  search.isLoading = payload;
  search.isLoadingResults = payload;
});

export const setLocationSearchUrlData = createSetter(
  ({ locationSearch, search }, { lastLocation, currentHash, currentHashPage }) => {
    locationSearch.lastLocation = lastLocation;
    locationSearch.currentHash = currentHash;
    locationSearch.currentHashPage = currentHashPage;
    search.lastLocation = lastLocation;
    search.currentHash = currentHash;
    search.currentHashPage = currentHashPage;
  },
);

const toPath = (history, { pathname, search }) => history.createHref({ pathname, search });

const updateAnalyticsConfig = (path) => {
  const { location: { protocol, host } = {} } = window || {};
  const url = protocol && host ? `${protocol}//${host}${path}` : path;

  updateURL(url);
};

const isSameLocation = (actualLocation, nextLocation) =>
  actualLocation.pathname === nextLocation.pathname && actualLocation.search === nextLocation.search;
const handleRequestError = (requestError) => {
  const wasCancelled = isCancel(requestError);

  if (!wasCancelled) {
    setLocationSearchError(requestError);
  }

  return { showLoading: wasCancelled };
};

const cancelLocationSearch = () => {
  const cancelToken = getLocationSearchCancelToken();

  if (cancelToken) {
    cancelToken.cancel();
    setLocationSearchCancelToken(null);
  }
};

const cleanPreviousState = () => {
  setLocationSearchIsLoading(true);
  cancelLocationSearch();
  setLocationSearchError(null);
};

const getRequestOptions = () => {
  const cancelToken = CancelToken.source();

  setLocationSearchCancelToken(cancelToken);

  return { cancelToken: cancelToken.token };
};

export const cleanPageFromHash = (hash = '') => {
  const rawNumber = parseInt(hash.replace('#', ''), 10);

  return Math.abs(rawNumber);
};

export const setLocationSearchLastLocation = createSetter(({ locationSearch }, payload) => {
  locationSearch.lastLocation = payload;
});

export const setLocationSearchCurrentHash = createSetter((state, payload) => {
  state.locationSearch.currentHash = payload;
  state.search.currentHashPage = cleanPageFromHash(payload);
});

const updateHash = (hash) => (loadingState) => {
  setLocationSearchCurrentHash(hash);

  return loadingState;
};

const handleServerResponse = ({ data, redirect }) => {
  if (redirect) {
    window.location.href = redirect;

    return { showLoading: true };
  }

  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  setLocationSearchInitialState(data);

  return { showLoading: false };
};

export const doLocationSearch = (location = {}) => {
  const lastLocation = getLocationSearchLastLocation();

  cleanPreviousState();

  if (isSameLocation(lastLocation, location)) {
    setLocationSearchCurrentHash(location.hash);
    setLocationSearchIsLoading(false);

    return Promise.resolve(null);
  }

  const history = getHistory();

  updateAnalyticsConfig(toPath(history, location));

  return ApiService.getSearchData(location, getRequestOptions())
    .then(handleServerResponse)
    .then(updateHash(location.hash))
    .catch((e) => {
      handleRequestError(e);
      setLocationSearchLastLocation(location);
      setLocationSearchIsLoading(false);
    })
    .then(({ showLoading = false } = {}) => {
      setLocationSearchLastLocation(location);

      return setLocationSearchIsLoading(showLoading);
    });
};

export const setLocationSearchInitialState = createSetter((state, payload) => {
  state.locationSearch.initialState = payload;
  Object.entries(payload).forEach(([key, value]) => {
    state.search[key] = value;
  });

  state.search.sameItemList = getRepeatProductObject(payload.results);
});

export const setHistoryListener = (currentLayout) => {
  const currentHistory = getHistory();

  if (!currentHistory || currentLayout !== LAYOUTS.TYPE_MAP) {
    return;
  }

  setLocationSearchUrlData({
    lastLocation: currentHistory.location,
    currentHash: currentHistory.location.hash,
    currentHashPage: cleanPageFromHash(currentHistory.location.hash),
  });
  currentHistory.listen(doLocationSearch);
};
