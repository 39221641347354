/* eslint-disable import/order */
const get = require('lodash/get');

const searchApiClient = require('../client/restclient')({
  timeout: process.env.NODE_ENV === 'production' ? 5000 : 5000,
  baseURL: '/',
  retry: {
    maxRetries: 1,
    delay: 0,
  },
});

const createRedirectHandler = (requestURL) => {
  const addRedirectToResponse = ({ data, request, headers }) => {
    const isJSON = get(headers, 'content-type', '').indexOf('application/json') > -1;
    const responseURL = get(request, 'responseURL', '');
    const isValidRedirection = !isJSON && responseURL.indexOf(requestURL) === -1;

    return { data, redirect: isValidRedirection ? responseURL : null };
  };

  return addRedirectToResponse;
};

const { SEARCH_MAPS_BASE_PATH } = require('../constants/paths');

class Service {
  static getSearchData({ pathname, search = '' }, requestOptions = {}) {
    const url = `${pathname}${search}`;
    const addRedirectToResponse = createRedirectHandler(url);

    return searchApiClient
      .get(`${SEARCH_MAPS_BASE_PATH}${url}`, {
        headers: {
          Encoding: 'UTF-8',
          'Content-Type': 'application/json',
        },
        ...requestOptions,
      })
      .then(addRedirectToResponse);
  }
}

module.exports = Service;
