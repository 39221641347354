/* eslint-disable no-underscore-dangle */
import { createBrowserHistory, createMemoryHistory } from 'history';

import { canUseDOM } from './dom-utils';

let history;

function getHistory(config) {
  if (!canUseDOM) {
    if (history) {
      return history;
    }

    history = createMemoryHistory(config);

    return history;
  }

  if (window.__HISTORY__) {
    return window.__HISTORY__;
  }

  window.__HISTORY__ = createBrowserHistory(config);

  return window.__HISTORY__;
}

export default getHistory;
