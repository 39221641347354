const querystring = require('querystring');

const merge = require('lodash/merge');
const restclient = require('nordic/restclient');

const DEFAULT_RESTCLIENT_OPTIONS = {
  headers: {
    common: {
      'X-Requested-With': 'XMLHttpRequest',
    },
  },
};

module.exports = (options) => {
  const client = restclient(merge({}, DEFAULT_RESTCLIENT_OPTIONS, options));

  client.originalDoRequest = client.doRequest;
  client.doRequest = function doRequest(args) {
    return this.originalDoRequest(args).catch((err) => {
      const res = err?.response;

      if (res && res.status === 401 && res.data && res.data.data.login) {
        let stringLoginParams = '';

        if (args.loginParams) {
          stringLoginParams = `&${querystring.stringify(args.loginParams)}`;
        }

        window.location = `${res.data.data.login}${stringLoginParams}`;

        return;
      }

      if (res && res.status === 301 && res.data.location) {
        window.location = res.data.location;

        return;
      }

      throw err;
    });
  };

  return client;
};
