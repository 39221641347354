import { DesktopView } from '../../pages/search/components/map/main';
import { renderView } from '../../utils/render';

const View = (props) => renderView(DesktopView, props);

export { getServerSideProps, setPageSettings } from '../../pages/search/routes/main';

export const hydrate = 'hydrateRoot';

export default View;
